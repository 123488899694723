<template>
    <v-container fluid>
        <v-form @submit.prevent="getTrackingContainers">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12" >
                                <v-subheader class="headline">{{ $t('search_container') }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" >
                                <ValidationProvider ref="container_name" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="container_name" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-transfer"
                                                  :label="$t('container_name')" color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-0 py-6">
                        <v-row>
                            <v-col cols="12"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>

                    <v-btn class="pa-3"
                           color="primary"
                           @click="addExcel">
                        <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                        {{ $t('import_excel') }}
                    </v-btn>

                    <v-btn class="pa-3 ml-3"
                           color="primary"
                           @click="dialogAdd=true">

                        {{ $t('create') }}
                    </v-btn>

            <input ref="uploadExcelContainer" class="d-none" type="file"
               accept=".xlsx, .xls" @change="onFileChangedMapMarker">
        </v-card>

        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table item-key="id" ref="table"  :headers="headers" :items="tracking_containerItems"
                              :loading="loading" :locale="lang" :loading-text="$t('loading_please_wait')"
                              class="sortable-table elevation-1" hide-default-footer disable-pagination>
                    <template v-slot:item.sort>
                        <v-btn icon class="handle">
                            <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.container="props">
                        <v-edit-dialog :return-value.sync="props.item.container" large persistent
                                       :disabled="(props.item.container.length <= 3)"
                                       @save="saveTrackingContainer(props.item)" :save-text="$t('save')"
                                       :cancel-text="$t('cancel')">
                            <v-icon class="mr-2">mdi-square-edit-outline</v-icon>
                            {{ props.item.container }}
                            <template v-slot:input>
                                <v-text-field v-model="props.item.container"
                                              :disabled="loading"
                                              :label="$t('container_name')" color="primary" clearable
                                              autofocus></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <template v-slot:item.created_at="{ item }">{{ $moment(item.created_at).format('YYYY-MM-DD') }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-icon @click="deleteTrackingContainer(item)" :title="$t('delete')">
                            mdi-delete-outline
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialogAdd" max-width="800" persistent eager>
            <v-card>
                <ValidationObserver ref="observerForm" v-slot="{ invalid, validated, passes, validate }">
                    <v-card-title>
                        <span class="headline">
                            {{ $t('container_creation') }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <ValidationProvider ref="container_name" rules="required|min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="container_name_create" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-folder-text-outline"
                                                  :label="$t('container_name')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider ref="application_number" rules="required|min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="application_number_create" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-folder-text-outline"
                                                  :label="$t('application_number')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="gray darken-1" class="ml-1 ml-sm-2" text @click="closeDialogTrackingContainer">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text :loading="loadingSaveTrackingContainer"
                               :disabled="invalid || loadingSaveTrackingContainer" class="mr-1 mr-sm-2"
                               @click="addTrackingContainer()">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"
    import Sortable from 'sortablejs'

    export default {
        name: "TrackingContainers",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                container_name: null,
                container_name_create: null,
                application_number: null,
                application_number_create: null,
                loading: false,
                tracking_containerItems: [],
                dialogAdd:false,
                loadingSaveTrackingContainer:false,
                headers: [
                    {
                        text: "",
                        align: "left",
                        sortable: false,
                        value: "sort",
                    },
                    {
                        text: this.$t('container_name'),
                        align: "left",
                        sortable: false,
                        value: "container"
                    },
                    {
                        text: this.$t('application_number'),
                        align: "center",
                        sortable: false,
                        value: "application_number"
                    },
                    {
                        text: this.$t('date_created'),
                        align: "center",
                        sortable: false,
                        value: "created_at"
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
                excelContainer: null,
            }
        },
        computed: {
            ...mapGetters(['lang']),
        },
        mounted() {

            let table = this.$refs.table.$el.querySelector("tbody");
            Sortable.create(table, {
                handle: ".handle",
                onEnd: this.dragReorder
            });
            this.getTrackingContainers()

        },
        methods: {
            editTrackingContainer(item) {
                this.$router.push({
                    name: 'tracking_container.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            closeDialogTrackingContainer() {
                this.dialogAdd = false
                this.container_name = null
            },
            dragReorder({newIndex, oldIndex}) {
                const rowSelected = this.tracking_containerItems.splice(oldIndex, 1)[0];
                this.tracking_containerItems.splice(newIndex, 0, rowSelected);
                this.saveSortTrackingContainer()
            },
            async onFileChangedMapMarker(e) {
                if (confirm(this.$t('are_you_sure_you_want_to_delete_this_file'))) {
                    var _this = this
                    this.progress = 0
                    this.loading = true
                    var formData = new FormData()

                    this.excelContainer = await this.convertBlobToBase64(e.target.files[0])

                    if (this.excelContainer) {

                        var mimeType = null
                        var blob = this.dataURL64toBlob(this.excelContainer)
                        if (blob.type === "application/vnd.ms-excel" || blob.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                            mimeType =  blob.type;
                        }
                        if (mimeType && blob) {
                            formData.append('tracking_id', this.$route.params.tracking_id)
                            formData.append('excel', blob, mimeType)
                            await this.$http
                                .post('admin/excel_container', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    progress(e) {
                                        if (e.lengthComputable) {
                                            _this.progress = Math.round((e.loaded / e.total) * 100)
                                        }
                                    }
                                })
                                .then(res => {
                                    this.$toastr.success(this.$t('company_has_been_added'))
                                    this.getTrackingContainers()
                                })
                                .catch(err => {
                                    this.$toastr.error(this.$t('company_has_not_been_added'))
                                    if (err && err.body && err.body.message) {
                                        for (let prop in err.body.errors) {
                                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                                if (_this.$refs[prop]) {
                                                    _this.$refs[prop].setErrors([
                                                        err.body.errors[prop][0]
                                                    ])
                                                }
                                            }
                                        }
                                        if (!err.body.errors) {
                                            this.$toastr.error(err.body.message)
                                        }
                                    }
                                })
                                .finally(end => {
                                    this.progress = 0
                                    this.loading = false
                                })
                        }
                    }

                }



            },
            addExcel() {
                this.$refs.uploadExcelContainer.click()
            },
            async getTrackingContainers() {
                var _this = this
                this.progress = 0
                this.loading = true
                let params = {}


                if (this.container_name) {
                    params.container_name = this.container_name
                }



                params.tracking_id = this.$route.params.tracking_id;


                await this.$http
                    .get("admin/tracking_container", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.tracking_containerItems = res.body.data
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('failed_to_get_list_tracking_containers'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deleteTrackingContainer(item) {
                if (confirm(this.$t('delete_container'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/tracking_container/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('tracking_container_has_been_deleted'))
                            this.getTrackingContainers()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('tracking_container_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async saveTrackingContainer(item) {
                if (item.container.length < 3) {
                    this.$toastr.error(this.$t('container_has_not_been_updated'));
                    this.tracking_containerItems = []
                    this.getTrackingContainers()
                    return
                }
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (item.container) {
                    formData.append('container_name', item.container)
                }
                // Update
                await this.$http
                    .put(`admin/tracking_container/${item.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        // console.log(res.data);
                        this.$toastr.success(this.$t('container_has_been_updated'));
                        //this.forceRerender();
                    })
                    .catch(err => {
                        // console.log(err.data);
                        this.$toastr.error(this.$t('container_has_not_been_updated'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async addTrackingContainer(item) {
                if (this.container_name_create.length < 3) {
                    this.$toastr.error(this.$t('container_has_not_been_updated'));
                    return
                }
                if (this.application_number_create.length < 3) {
                    this.$toastr.error(this.$t('container_has_not_been_updated'));
                    return
                }

                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.container_name_create) {
                    formData.append('container_name_create', this.container_name_create)
                }
                if (this.application_number_create) {
                    formData.append('application_number', this.application_number_create)
                }
                formData.append('tracking_id', this.$route.params.tracking_id)

                // Add
                await this.$http
                    .post(`admin/tracking_container`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        // console.log(res.data);
                        this.$toastr.success(this.$t('container_has_been_updated'));
                        this.closeDialogTrackingContainer();
                        this.getTrackingContainers();
                    })
                    .catch(err => {
                        // console.log(err.data);
                        this.$toastr.error(this.$t('container_has_not_been_updated'));
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveSortTrackingContainer() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.tracking_containerItems && this.tracking_containerItems.length > 0) {
                    var i = 0;
                    for (i = 0; i < this.tracking_containerItems.length; i++) {
                        if (this.tracking_containerItems[i].id !== undefined && this.tracking_containerItems[i].id > 0) {
                            formData.append(`sort[${i}]`, this.tracking_containerItems[i].id)
                        }
                    }
                }
                await this.$http
                    .put(`admin/tracking_container/${this.$route.params.tracking_id}/sort`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        // console.log(res.data);
                        this.$toastr.success(this.$t('container_sorting_updated'));
                        //this.getTrackingContainers();
                    })
                    .catch(err => {
                        // console.log(err.data);
                        this.$toastr.error(this.$t('container_sorting_not_updated'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
        }
    }
</script>
